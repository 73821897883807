import axios from "axios";
import jwt_decode from "jwt-decode";

import {API_SERVER} from "../config/Constant";

const axiosInstance = axios.create({
    baseURL: `${API_SERVER}`,
    headers: {"Content-Type": "application/json", "Accept-Language": "tr-TR"},
});

axiosInstance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

const requestHandler = request => {
    document.body.classList.add('loading-indicator');

    const token = localStorage.getItem("token");

    if (token) {
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();

        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            window.location = '/#/logout';
        }
    }

    request.headers.Authorization = 'Bearer ' + token;

    return request;
};

const responseHandler = response => {
    document.body.classList.remove('loading-indicator');
    if (response.status === 401) {
        window.location = '/#/login';
    }

    return response;
};

const errorHandler = error => {

    document.body.classList.remove('loading-indicator');
    if (!error.response) {
        window.location = '/#/errors';
    }
    if (error?.response.status === 401) {
        window.location = '/#/login';
    }

    return Promise.reject(error);
};

export default axiosInstance;
